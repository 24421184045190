<template>
  <div v-html="$t('help.html')" />
</template>

<script>
export default {
  name: "help",

  metaInfo: {
    title: "Help",
  },
};
</script>

<style scoped></style>
