import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"CookieLaw py-3 px-6",attrs:{"tile":"","elevation":"12"}},[_c('div',{staticClass:"d-flex flex-row justify-center"},[_c('div',{staticClass:"align-self-center pr-3",class:{
        'text-sm': _vm.$vuetify.breakpoint.smAndDown,
      }},[_vm._v(" "+_vm._s(_vm.$t("cookiePolicy.introduction"))+" "),_c('router-link',{staticClass:"table-link text-decoration-none",attrs:{"to":"/access","title":_vm.$t('cookiePolicy.readMoreButton')}},[_vm._v(_vm._s(_vm.$t("cookiePolicy.readMoreButton"))+" "),_c(VIcon,{attrs:{"small":"","color":"primary"}},[_vm._v("fas fa-cookie-bite")])],1)],1),_c('div',{staticClass:"align-self-center"},[_c(VBtn,{attrs:{"color":"primary","title":"Close cookie notification"},on:{"click":function($event){return _vm.$emit('accept')}}},[_vm._v(" OK ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }