import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VNavigationDrawer,{staticClass:"elevation-4",style:(_vm.footerStyle),attrs:{"app":"","value":_vm.drawer,"clipped":"","fixed":"","width":"350","disable-route-watcher":"","mobile-breakpoint":"960","color":"blue-grey lighten-4"},on:{"input":function($event){return _vm.$emit('update:drawer', $event)}}},[_c(VList,{attrs:{"expand":""}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold text-center text-uppercase mb-2",staticStyle:{"font-size":"1.15rem"}},[_vm._v(_vm._s(_vm.$t("frontPage.quickSearch")))]),_c('TextFieldWrapper',{staticClass:"search-drawer-text-field",attrs:{"value":_vm.search.q.value,"clearable":"","solo":"","placeholder":_vm.$t('frontPage.quickSearch'),"clear-icon":"fas fa-times"},on:{"input":function($event){return _vm.updateSearchFieldDebounced({ id: 'q', value: $event })}}})],1)],1),_c(VDivider),_c(VListGroup,{attrs:{"value":_vm.showAdditionalFilters,"active-class":"blue-grey lighten-3","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemTitle,{staticClass:"font-weight-bold text-center text-uppercase",staticStyle:{"font-size":"1.15rem"}},[_vm._v(_vm._s(_vm.$t("search.drawer.additionalFilters")))])]},proxy:true}])},[_c(VDivider),_vm._l((_vm.searchTextIds),function(id){return _c(VListItem,{key:id},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"py-1",attrs:{"cols":"12"}},[_c('SelectWrapper',{attrs:{"use-custom-prepend-inner":_vm.$t(`search.table.${id}`),"items":_vm.lookUpTypes,"value":_vm.search[id].lookUpType,"readonly":id === 'coordinates'},on:{"input":function($event){return _vm.updateSearchField({ id: id, lookUpType: $event })}}})],1),_c(VCol,{staticClass:"py-1",attrs:{"cols":"12"}},[_c('TextFieldWrapper',{staticClass:"search-drawer-text-field",attrs:{"value":_vm.search[id].value,"dense":"","clearable":"","solo":"","readonly":id === 'coordinates',"placeholder":_vm.$t(
                  `search.table.${id}${
                    id === 'coordinates' ? 'ReadOnly' : ''
                  }`
                ),"clear-icon":"fas fa-times"},on:{"input":function($event){return _vm.updateSearchFieldDebounced({ id: id, value: $event })}}})],1)],1)],1)}),_c('div',{staticClass:"my-2"}),_c(VDivider),_c(VCard,{staticClass:"checkboxes",attrs:{"flat":"","tile":"","color":"transparent","hover":""}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VCardTitle,{staticClass:"checkboxes--title font-weight-bold text-uppercase py-2 px-6",class:{
              'blue-grey lighten-3': _vm.search.map.showCheckboxes,
              'blue-grey lighten-2': _vm.search.map.showCheckboxes && hover,
            },staticStyle:{"font-size":"0.875rem"},on:{"click":function($event){return _vm.updateSearchField({
                id: 'map',
                showCheckboxes: !_vm.search.map.showCheckboxes,
              })}}},[_vm._v(" "+_vm._s(_vm.$t(`search.table.map`))+" "),_c(VSpacer),(_vm.search.map.value)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-6",attrs:{"small":"","color":"error","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.resetFacet('map')}}},'v-btn',attrs,false),on),[_c(VBadge,{attrs:{"color":"transparent","bottom":"","overlap":""}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("fas fa-trash")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("search.drawer.clearFilters", { field: _vm.$t(`search.table.map`), })))])]):_vm._e(),(_vm.search.map.showCheckboxes)?_c(VIcon,[_vm._v("fas fa-angle-up")]):_c(VIcon,[_vm._v("fas fa-angle-down")])],1)]}}])}),_c(VDivider),_c(VExpandTransition,[_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(_vm.search.map.showCheckboxes),expression:"search.map.showCheckboxes"}],staticClass:"transition-fast-in-fast-out pa-0",class:{ 'blue-grey lighten-5': _vm.search.map.showCheckboxes }},[_c('map-wrapper',{attrs:{"map-id":"search-map","open":_vm.search.map.showCheckboxes,"response-results":_vm.responseResults,"response-results-count":_vm.responseResultsCount,"activate-search":""},on:{"update":_vm.doSearch}})],1)],1)],1),_vm._l((_vm.searchCheckboxIds),function(id){return _c(VCard,{key:id,staticClass:"checkboxes",attrs:{"flat":"","tile":"","color":"transparent","hover":""}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VCardTitle,{staticClass:"checkboxes--title font-weight-bold text-uppercase py-2 px-6",class:{
              'blue-grey lighten-3': _vm.search[id].showCheckboxes,
              'blue-grey lighten-2': _vm.search[id].showCheckboxes && hover,
            },staticStyle:{"font-size":"0.875rem"},on:{"click":function($event){return _vm.updateSearchField({
                id: id,
                showCheckboxes: !_vm.search[id].showCheckboxes,
              })}}},[_vm._v(" "+_vm._s(_vm.$t(`search.table.${id}`))+" "),_c(VSpacer),(_vm.getActiveCheckboxesCount(id) > 0)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-6",attrs:{"small":"","color":"error","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.resetFacet(id)}}},'v-btn',attrs,false),on),[_c(VBadge,{attrs:{"color":"transparent","bottom":"","overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',{staticClass:"black--text font-weight-bold"},[_vm._v(_vm._s(_vm.getActiveCheckboxesCount(id)))])]},proxy:true}],null,true)},[_c(VIcon,{attrs:{"small":""}},[_vm._v("fas fa-trash")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("search.drawer.clearFilters", { field: _vm.$t(`search.table.${id}`), })))])]):_vm._e(),(_vm.search[id].showCheckboxes)?_c(VIcon,[_vm._v("fas fa-angle-up")]):_c(VIcon,[_vm._v("fas fa-angle-down")])],1)]}}],null,true)}),_c(VDivider),_c(VExpandTransition,[(_vm.search[id].showCheckboxes)?_c(VCardText,{staticClass:"transition-fast-in-fast-out pb-0",class:{ 'blue-grey lighten-5': _vm.search[id].showCheckboxes }},[_c(VRow,{attrs:{"no-gutters":""}},[_vm._l((_vm.getCheckboxes(
                  id,
                  _vm.search[id].showCheckboxes,
                  _vm.search[id].showMore
                )),function(entity,key){return _c(VCol,{key:key,staticClass:"px-1 pb-1",attrs:{"cols":"12"}},[_c(VCheckbox,{staticClass:"mt-0 mb-2",attrs:{"color":"blue-grey darken-3","input-value":_vm.search[id].value &&
                    _vm.search[id].value.includes(`"${entity}"`),"hide-details":"","dense":""},on:{"change":function($event){return _vm.updateCheckbox({
                      id: id,
                      bool: $event,
                      value: _vm.search[id].value,
                      fieldName: entity,
                    })}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(entity)+" "),_c('span',{staticClass:"font-italic font-weight-light",staticStyle:{"font-size":"0.875rem"}},[_vm._v("("+_vm._s(_vm.getCheckboxesCount(id)[key])+")")])])]},proxy:true}],null,true)})],1)}),_c(VCol,{attrs:{"cols":"12"}},[(_vm.getCheckboxesLength(id) > 4)?_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.search[id].showCheckboxes),expression:"search[id].showCheckboxes"}],staticClass:"mx-4 mb-2 font-weight-bold",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.updateSearchField({
                      id: id,
                      showMore: !_vm.search[id].showMore,
                    })}}},[(_vm.search[id].showMore)?_c('span',[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("fas fa-minus")]),_vm._v(" "+_vm._s(_vm.$t("search.drawer.less")))],1):_c('span',[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("fas fa-plus")]),_vm._v(" "+_vm._s(_vm.$t("search.drawer.more")))],1)]):_vm._e()],1)],2)],1):_vm._e()],1)],1)}),_vm._l((_vm.searchSingleCheckboxIds),function(id){return _c(VListItem,{key:id},[_c(VCheckbox,{staticClass:"mt-0 mb-2",attrs:{"color":"blue-grey darken-3","input-value":_vm.search[id].value,"label":_vm.$t(`search.drawer.${id}`),"true-value":"true","false-value":null,"hide-details":"","dense":""},on:{"change":function($event){return _vm.updateSearchFieldDebounced({ id: id, value: $event })}}})],1)})],2),_c(VDivider)],1),_c(VRow,{staticClass:"px-3 pb-5 mt-1",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"color":"error"},on:{"click":_vm.reset}},[_vm._v(" "+_vm._s(_vm.$t("search.drawer.resetSearch"))+" "),_c(VIcon,{attrs:{"right":""}},[_vm._v("far fa-trash-alt")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }