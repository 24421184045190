import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"flat":""}},[(_vm.localities.length === 0)?_c(VRow,{staticClass:"my-4",attrs:{"no-gutters":"","justify":"center"}},[_c(VCol,{staticStyle:{"max-width":"500px"},attrs:{"cols":"12"}},[_c(VAlert,{staticClass:"mb-0",attrs:{"text":"","border":"left","icon":"fas fa-search","color":"secondary"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("search.mapNoResults"))+" ")]),(!_vm.search.has_map.value)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("search.mapNoResultsFilterInfo"))+" "),_c(VBtn,{attrs:{"x-small":"","color":"secondary"},on:{"click":function($event){return _vm.updateSearchField({ id: 'has_map', value: 'true' })}}},[_vm._v(_vm._s(_vm.$t("search.addFilter")))])],1):_vm._e()])],1)],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.localities.length > 0),expression:"localities.length > 0"}],staticClass:"map"},[_c('map-wrapper',{attrs:{"response-results":_vm.responseResults,"response-results-count":_vm.responseResultsCount,"height":_vm.isDetailView ? '50vh' : '70vh',"is-detail-view":_vm.isDetailView}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }