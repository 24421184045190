import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{staticStyle:{"z-index":"2000"},attrs:{"value":_vm.showHelp,"scrollable":"","max-width":_vm.$vuetify.breakpoint.mdAndUp ? '960px' : ''},on:{"click:outside":function($event){return _vm.$emit('close')}}},[_c(VCard,[_c(VCardTitle,{staticClass:"font-weight-medium pb-3",staticStyle:{"font-size":"2rem"}},[_vm._v(_vm._s(_vm.$t("searchHelp.title")))]),_c(VDivider),_c(VCardText,{staticStyle:{"max-height":"350px","padding":"20px 24px 20px","font-size":"1.125rem"}},[_c(VRow,{attrs:{"no-gutters":"","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"10","lg":"9"}},[_c('ul',[_c('li',[_vm._v(" "+_vm._s(_vm.$t("searchHelp.help1"))+" ("),_c('router-link',{staticClass:"help-link",attrs:{"to":{ path: 'search', query: { q: '*oa' } }}},[_vm._v("*")]),_vm._v("). ")],1),_c('li',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('searchHelp.help2'))}}),_c('router-link',{staticClass:"help-link",attrs:{"to":{
                  path: 'search',
                  query: { q: '+bryozoa -germany +tub*' },
                }}},[_vm._v("+bryozoa -germany +tub*")]),_vm._v(". ")],1),_c('li',[_vm._v(" "+_vm._s(_vm.$t("searchHelp.help4.prepend"))+" "),_c('router-link',{staticClass:"help-link",attrs:{"to":{
                  path: 'search',
                  query: { q: 'stratigraphy:Burtnieki*' },
                }}},[_vm._v("stratigraphy:Burtnieki*")]),_vm._v(" "+_vm._s(_vm.$t("searchHelp.help4.append"))+" ")],1)])])],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green darken-1","large":"","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" OK ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }