<template>
  <div class="About" v-html="$t('about.html')" />
</template>

<script>
export default {
  name: "About",
};
</script>

<style scoped></style>
