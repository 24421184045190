import { render, staticRenderFns } from "./HelpButton.vue?vue&type=template&id=f2896a32&scoped=true"
import script from "./HelpButton.vue?vue&type=script&lang=js"
export * from "./HelpButton.vue?vue&type=script&lang=js"
import style0 from "./HelpButton.vue?vue&type=style&index=0&id=f2896a32&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2896a32",
  null
  
)

export default component.exports