import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VCard,{staticClass:"StatsCard pa-4 text-center",class:{ 'hover-enter primary--text': hover, 'hover-leave': !hover },attrs:{"flat":""},on:{"click":_vm.viewInfo}},[_c('div',{staticClass:"animate__animated",class:{
        animate__headShake: hover,
      },staticStyle:{"font-size":"1.25rem"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c('div',{staticClass:"font-weight-bold animate__animated",class:{
        animate__headShake: hover,
      },staticStyle:{"font-size":"1.75rem"}},[_vm._v(" "+_vm._s(_vm.count)+" ")])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }