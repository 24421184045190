import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"transition":"slide-y-transition","offset-y":"","z-index":"2101"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"aria-label":"select language","text":"","x-small":""}},on),[_c('span',{class:[_vm.flagCommonStyles, _vm.flagStyles()]})])]}}])},[_c(VList,{attrs:{"color":"primary","dark":"","dense":""}},_vm._l((_vm.languages),function(item){return _c(VListItem,{key:item.text,on:{"click":function($event){return _vm.changeLang(item.value)}}},[_c(VListItemTitle,{staticClass:"d-flex"},[_c('div',{staticClass:"mr-2",class:[_vm.flagCommonStyles, _vm.flagStyles(item.value)]}),_c('div',{staticClass:"align-self-center font-weight-bold"},[_vm._v(_vm._s(item.text))])])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }