import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VHover,{attrs:{"close-delay":"1600"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VCard,{staticClass:"d-flex flex-column HoverCard",style:(`background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)), url("${_vm.card.image}");`),attrs:{"elevation":hover ? 12 : 6,"height":"300","hover":"","aria-label":_vm.card.imageAltText},on:{"mouseleave":_vm.handleMouseLeave,"mouseenter":_vm.handleMouseEnter,"click":function($event){return _vm.goToSearchView(_vm.card.url)}}},[_c(VOverlay,{attrs:{"absolute":"","value":hover}},[_c('div',{staticClass:"d-flex flex-column text-center"},[_c('div',{staticClass:"v-card__title justify-center text-uppercase font-weight-bold animate__animated",class:{
            animate__fadeInUp: hover,
            animate__fadeOutDown: _vm.card.isLeaving,
          }},[_vm._v(" "+_vm._s(_vm.card.title)+" ")]),_c('div',{staticClass:"v-card__text text-center animate__animated font-weight-medium",class:{
            animate__fadeInUp: hover,
            animate__fadeOutDown: _vm.card.isLeaving,
          }},[_vm._v(" "+_vm._s(_vm.card.text)+" ")]),_c('div',{staticClass:"v-card__actions justify-center animate__animated",class:{
            animate__fadeInUp: hover,
            animate__fadeOutDown: _vm.card.isLeaving,
          }},[_c(VBtn,{staticClass:"font-weight-bold white--text",attrs:{"color":"black","elevation":"6","to":_vm.card.url}},[_vm._v(_vm._s(_vm.card.button))])],1)])]),_c(VSpacer),_c(VCardTitle,{staticClass:"justify-center text-uppercase font-weight-bold animate__animated animate__faster white--text",class:{
        animate__fadeOutUp: !_vm.card.isLeaving && hover,
        animate__fadeInDown: _vm.card.isLeaving,
      }},[_vm._v(_vm._s(_vm.card.title))])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }