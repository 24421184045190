import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c('app-header'),_c(VMain,{class:{
      'pt-2': _vm.$route.name === 'FrontPage',
      'custom-pb': _vm.$vuetify.breakpoint.smAndDown,
    }},[_c('router-view')],1),(_vm.cookieLaw)?_c('CookieLaw',{on:{"accept":_vm.closeCookieLaw}}):_vm._e(),_c('app-footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }