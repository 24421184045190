<template>
  <div v-html="$t('partners_and_providers.html')" />
</template>

<script>
export default {
  name: "PartnersAndProviders",

  metaInfo: {
    title: "Partners and Providers",
  },
};
</script>

<style scoped></style>
