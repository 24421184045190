import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VNavigationDrawer,{staticStyle:{"z-index":"2050"},attrs:{"value":_vm.drawer,"app":"","right":"","temporary":"","dark":"","color":"primary"},on:{"input":function($event){return _vm.$emit('update:navigationDrawer', $event)}}},[_c(VList,{attrs:{"dense":""}},[_c(VSubheader,[_vm._v("ROUTES")]),_vm._l((_vm.routes),function(item){return _c(VListItem,{key:item.name,attrs:{"to":item.to}},[_c(VListItemAction,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.text)+" ")])],1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }