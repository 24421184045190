import { VPagination } from 'vuetify/lib/components/VPagination';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.numberOfResults >= 0)?_c('div',{staticClass:"pa-2 d-flex flex-column flex-lg-row flex-nowrap justify-space-between align-center pagination"},[_c('div',[_c('SelectWrapper',{attrs:{"value":_vm.paginateBy,"items":_vm.paginateByItems,"is-pagination":""},on:{"change":function($event){return _vm.$emit('update:paginateBy', $event)}}})],1),_c('div',[_c(VPagination,{class:{
        'justify-end font-small': _vm.$vuetify.breakpoint.smAndUp,
        'font-smaller': _vm.$vuetify.breakpoint.xsOnly,
      },staticStyle:{"font-size":"0.75rem"},attrs:{"value":_vm.page,"circle":"","prev-icon":"fas fa-angle-left","next-icon":"fas fa-angle-right","length":Math.ceil(_vm.numberOfResults / _vm.paginateBy),"total-visible":_vm.$vuetify.breakpoint.smAndDown
          ? _vm.$vuetify.breakpoint.xsOnly
            ? 4
            : 5
          : 7},on:{"input":function($event){return _vm.$emit('update:page', $event)}}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }