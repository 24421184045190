<template>
  <div v-html="$t('efg.html')" />
</template>

<script>
export default {
  name: "Efg",

  metaInfo: {
    title: "About EFG",
  },
};
</script>

<style scoped></style>
