<template>
  <div v-html="$t('access.html')" />
</template>

<script>
export default {
  name: "Access",

  metaInfo: {
    title: "Data Access",
  },
};
</script>

<style scoped></style>
