import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{staticStyle:{"z-index":"3000"},attrs:{"value":_vm.dialog,"persistent":"","fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","no-click-animation":""}},[_c(VCard,{attrs:{"tile":""}},[_c(VToolbar,{attrs:{"dark":"","color":"primary","height":"64"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.$emit('close:dialog')}}},on),[_c(VIcon,[_vm._v("fas fa-times")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("imageGallery.closeGallery")))])]),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t("imageGallery.imageGallery")))]),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"dark":"","icon":""},on:{"click":function($event){_vm.showGallery = !_vm.showGallery}}},on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("fas fa-th")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t(`imageGallery.${_vm.showGallery ? "hide" : "show"}Thumbnails`)))])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.$emit('close:dialog')}}},on),[_c(VIcon,[_vm._v("fas fa-times")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("imageGallery.closeGallery")))])])],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":_vm.showGallery ? 8 : 12,"sm":_vm.showGallery ? 9 : 12,"xl":_vm.showGallery ? 10 : 12}},[_c('div',{staticClass:"d-flex flex-column flex-nowrap fill-height justify-space-between"},[_c('div',{staticClass:"d-flex flex-row justify-space-between align-center image-overflow--control",class:{
              'image-control-66': _vm.showGallery && _vm.$vuetify.breakpoint.xsOnly,
              'image-control-75': _vm.showGallery && _vm.$vuetify.breakpoint.smAndUp,
              'image-control-83': _vm.showGallery && _vm.$vuetify.breakpoint.xlOnly,
            },style:(`height: calc(100vh - ${
              _vm.decreaseImageContainerHeightBy - 32
            }px)`)},[_c(VBtn,{staticClass:"ma-3",attrs:{"color":"primary","fab":"","small":""},on:{"click":_vm.showPrev}},[_c(VIcon,[_vm._v("fas fa-angle-left")])],1),_c(VBtn,{staticClass:"ma-3",attrs:{"color":"primary","fab":"","small":""},on:{"click":_vm.showNext}},[_c(VIcon,[_vm._v("fas fa-angle-right")])],1)],1),_c('div',{staticClass:"pa-4"},[_c('image-wrapper',{style:(`height: calc(100vh - ${_vm.decreaseImageContainerHeightBy}px)`),attrs:{"max-height":_vm.imageHeight.toString(),"image-src":_vm.images[_vm.currentIndex].originalImage,"alt-text":_vm.images[_vm.currentIndex].altText}})],1),_c('div',{staticClass:"image-info"},[_c(VCardText,{staticClass:"pa-6 font-weight-bold black--text",staticStyle:{"font-size":"1.125rem","line-height":"1.5"}},[(_vm.images[_vm.currentIndex].image_date)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("imageGallery.date"))+": "+_vm._s(_vm.images[_vm.currentIndex].image_date)+" ")]):_vm._e(),(_vm.images[_vm.currentIndex].image_licence)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("imageGallery.licence"))+": "+_vm._s(_vm.images[_vm.currentIndex].image_licence)+" ")]):_vm._e(),_c('div',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                    path: `specimen/${_vm.images[_vm.currentIndex].geocase_id}`,
                  }}},[_vm._v(_vm._s(_vm.$t("imageGallery.goToSpecimenView")))])],1),(_vm.images[_vm.currentIndex].originalImage)?_c('div',[_c('a',{staticClass:"link text-decoration-none",attrs:{"href":_vm.images[_vm.currentIndex].originalImage,"target":"UrlWindow"}},[_vm._v(_vm._s(_vm.$t("imageGallery.linkToImage"))+" "),_c(VIcon,{attrs:{"color":"primary","x-small":""}},[_vm._v("fas fa-external-link-alt")])],1)]):_vm._e()])],1)])]),(_vm.showGallery)?_c(VCol,{staticClass:"image-overflow--gallery white black--text pa-1",staticStyle:{"height":"calc(100vh - 64px)","overflow-y":"auto"},attrs:{"cols":"4","sm":"3","xl":"2"}},[_c(VRow,{attrs:{"no-gutters":""}},_vm._l((_vm.images),function(entity,index){return _c(VCol,{key:index,staticClass:"pa-1",attrs:{"cols":"12","md":"6"}},[_c(VCard,{staticClass:"image-hover",class:{ 'active-outline': _vm.currentIndex === index },style:(`outline-color: ${_vm.$vuetify.theme.themes.light.primary}`),attrs:{"light":"","flat":"","tile":""},on:{"click":function($event){return _vm.$emit('update:index', index)}}},[_c('image-wrapper',{attrs:{"contain":false,"max-height":"100","image-src":entity.thumbnailImage,"alt-text":entity.altText}})],1)],1)}),1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }