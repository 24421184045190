import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"static-page"},[_c(VCard,{staticClass:"py-6 pl-md-8 pr-md-6",attrs:{"elevation":"6","shaped":""}},[_c(VTabs,{class:{ 'v-item-group-mb-3': !_vm.isMdAndUp },attrs:{"optional":"","show-arrows":"","vertical":_vm.isMdAndUp}},[_vm._l((_vm.staticPages),function(item,index){return _c(VTab,{key:index,staticClass:"my-1 justify-start",class:{ 'v-tab--active': _vm.$route.path.endsWith(item.to) },attrs:{"to":item.to}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_vm._v(" "+_vm._s(item.text)+" ")],1)}),_c(VTabsItems,{staticClass:"px-4 px-md-0 pl-md-6 pt-4 pt-md-0"},[_c('router-view',{staticClass:"px-2 mx-auto static-page__router-view",class:`static-page__${_vm.$route.name.toLowerCase()}`})],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }