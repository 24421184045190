import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mb-6"},[_c(VCarousel,{attrs:{"height":"unset","prev-icon":"fas fa-angle-left","next-icon":"fas fa-angle-right","delimiter-icon":"fas fa-minus","cycle":_vm.cycleImages,"interval":"3000","hide-delimiters":_vm.images.length < 2,"show-arrows":_vm.images.length > 1},on:{"change":_vm.activeImage}},_vm._l((_vm.images),function(entity,index){return _c(VCarouselItem,{key:index,staticClass:"pa-2",attrs:{"href":entity.originalImage,"target":"UrlWindow"}},[_c('image-wrapper',{staticClass:"mx-auto",attrs:{"image-src":entity.thumbnailImage,"max-height":_vm.calculateImageMaxHeight(entity.imageHeight),"value":_vm.carouselItem,"alt-text":entity.altText}})],1)}),1),_c(VCard,{attrs:{"flat":""}},[_c(VList,{staticClass:"py-0",attrs:{"three-line":!!(_vm.date || _vm.licence)}},[_c(VListItem,[_c(VListItemContent,[(_vm.date)?_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.$t("imageGallery.date"))+": "+_vm._s(_vm.date))]):_vm._e(),(_vm.licence)?_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.$t("imageGallery.licence"))+": "+_vm._s(_vm.licence))]):_vm._e(),(_vm.url)?_c(VListItemSubtitle,[_c('a',{staticClass:"link text-decoration-none",attrs:{"href":_vm.url,"target":"UrlWindow"}},[_vm._v(_vm._s(_vm.$t("imageGallery.linkToImage"))+" "),_c(VIcon,{attrs:{"color":"primary","x-small":""}},[_vm._v("fas fa-external-link-alt")])],1)]):_vm._e()],1),(_vm.images.length > 1)?_c(VListItemAction,{staticClass:"align-self-center"},[_c(VSwitch,{attrs:{"hide-details":"","label":_vm.$t('imageGallery.cycleImages'),"inset":""},model:{value:(_vm.cycleImages),callback:function ($$v) {_vm.cycleImages=$$v},expression:"cycleImages"}})],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }