import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"transition":"slide-y-transition","offset-y":"","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(menu){return [_c(VTooltip,{attrs:{"bottom":"","open-delay":"500","z-index":"5000"},scopedSlots:_vm._u([{key:"activator",fn:function(tooltip){return [_c(VBtn,_vm._g(_vm._b({staticClass:"montserrat",attrs:{"color":"primary","aria-label":"export table","icon":""}},'v-btn',{ ...menu.attrs, ...tooltip.attrs },false),{ ...menu.on, ...tooltip.on }),[_c(VIcon,[_vm._v("mdi-file-export-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("search.table.tooltipExport")))])])]}}])},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.handleExportCsv()}}},[_c(VListItemTitle,[_vm._v("CSV")])],1),_c(VListItem,{on:{"click":function($event){return _vm.handleExportExcel()}}},[_c(VListItemTitle,[_vm._v("XLSX (Excel)")])],1),_c(VListItem,{on:{"click":function($event){return _vm.handleClipboard()}}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t("search.table.clipboard"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }