import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MglPopup',{attrs:{"close-on-click":_vm.popup.closeOnClick,"coordinates":_vm.popup.coordinates,"max-width":_vm.popupMaxWidth,"close-on-move":false,"focus-after-open":false}},[_c(VCard,{attrs:{"max-width":_vm.popupMaxWidth}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.activePopupData.locality))]),_c(VCardText,{staticClass:"pb-0"},[_c('div',[_vm._v("Lat: "+_vm._s(_vm.activePopupData.lat))]),_c('div',[_vm._v("Long: "+_vm._s(_vm.activePopupData.lng))])]),(_vm.activeNumFound)?_c(VCard,{attrs:{"max-width":_vm.popupMaxWidth,"flat":""}},[_c(VCardTitle,{staticClass:"text-subtitle-1 pb-2 text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$t("frontPage.map.numFound"))+" "),_c('b',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.activeNumFound))])]),_c(VDataTable,{attrs:{"disable-filtering":"","hide-default-footer":_vm.activeNumFound <= 10,"disable-sort":"","fixed-header":"","height":"200","dense":"","mobile-breakpoint":"100","headers":[
          { text: '', value: 'icon' },
          { text: _vm.$t('search.table.unitid'), value: 'unitid' },
          {
            text: _vm.$t('search.table.fullscientificname'),
            value: 'fullscientificname',
          },
        ],"items":_vm.activeDocs,"footer-props":{
          itemsPerPageOptions: [10, 25, 50, -1],
          itemsPerPageText: _vm.$t('frontPage.map.itemsPerPageText'),
          showFirstLastPage: true,
        }},scopedSlots:_vm._u([{key:"item.icon",fn:function({ item }){return [_c('router-link',{staticClass:"icon-link",staticStyle:{"text-decoration":"unset"},attrs:{"to":{ path: `specimen/${encodeURIComponent(item.geocase_id)}` },"title":_vm.$t('search.goToDetailView')}},[(_vm.getItemType(item) === 'fossil')?_c(VIcon,{attrs:{"small":"","color":"primary"}},[_vm._v("fas fa-fish")]):(_vm.getItemType(item) === 'mineral')?_c(VIcon,{attrs:{"small":"","color":"primary"}},[_vm._v("far fa-gem")]):(_vm.getItemType(item) === 'rock')?_c(VIcon,{attrs:{"small":"","color":"primary"}},[_vm._v("fas fa-mountain")]):(_vm.getItemType(item) === 'meteorite')?_c(VIcon,{attrs:{"small":"","color":"primary"}},[_vm._v("fas fa-meteor")]):_vm._e()],1)]}},{key:"item.unitid",fn:function({ item }){return [_c('router-link',{staticStyle:{"text-decoration":"unset"},attrs:{"to":{ path: `specimen/${encodeURIComponent(item.geocase_id)}` },"title":_vm.$t('search.goToDetailView')}},[_vm._v(" "+_vm._s(item.unitid)+" ")])]}},{key:"item.fullscientificname",fn:function({ item }){return [(item.mindat_id)?_c('div',[_c('a',{staticStyle:{"text-decoration":"unset","white-space":"nowrap"},attrs:{"target":"MindatWindow","title":_vm.$t('search.mindatLink')},on:{"click":function($event){return _vm.openMindatInNewWindow(item.mindat_url)}}},[_vm._v(_vm._s(item.fullscientificname)+" "),_c(VIcon,{attrs:{"small":"","color":"primary"}},[_vm._v("fas fa-external-link-square-alt")])],1)]):_c('div',[_vm._v(_vm._s(item.fullscientificname))])]}}],null,false,2276603646)})],1):_vm._e(),(_vm.activeNumFound === 0)?_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('clicked:searchButton')}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"x-small":""}},[_vm._v("fas fa-search")]),_vm._v(_vm._s(_vm.$t("frontPage.map.search")))],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }